
export const Apis = {
  BASE_URL: 'https://reachoutworldayapi.tniglobal.org/api',
  DATABASEMANAGEMENT_API_URL: 'https://databasemanagementapi.tniglobal.org/api',
  TNICC_ADMIN_API_URL: "https://tniccadminapi.tniglobal.org/api",
  RHAPSODYLANGUAGES_API_URL: 'https://mediathek.tniglobal.org/api',
  RHAPSODYLANGUAGES_URL: 'https://mediathek.tniglobal.org',
  PROGRAMS_API_URL: "https://ministryprogsapi.tniglobal.org/api",
  DATABASE_API_URL: "https://database.tniglobal.org/api",
  DATABASE_URL: "https://database.tniglobal.org"
}