import React, { Suspense, useState } from 'react';
import { MenuFoldOutlined, MenuUnfoldOutlined} from '@ant-design/icons';
import { Button, Col, Layout, Row, theme } from 'antd';
import MySidebar from './MySidebar';
import { useAuth } from '../contexts/authContext';
import PageWrapper from './PageWrapper/PageWrapper';
import SubHeader from './SubHeader/SubHeader';
import Page from './Page/Page';
import Card, { CardBody } from '../components/bootstrap/Card';
import { BrowserRouter as Router, Outlet, useParams, useLocation, useNavigate, Navigate } from "react-router-dom";
import Popovers from '../components/bootstrap/Popovers';
import BootButton, { IButtonProps } from '../components/bootstrap/Button';
import Icon from '../components/icon/Icon';
import useDarkMode from '../hooks/useDarkMode';
import Logout from '../pages/auth/Logout';


const { Header, Sider, Content } = Layout;

const LOADING = (
	<PageWrapper>
		<SubHeader>
			<div />
		</SubHeader>
		<Page>
			<div className='row h-100'>
				<div className='col-lg-6'>
					<Card stretch>
						<CardBody>
							<div />
						</CardBody>
					</Card>
				</div>
				<div className='col-lg-6'>
					<Card stretch='semi'>
						<CardBody>
							<div />
						</CardBody>
					</Card>
					<Card stretch='semi'>
						<CardBody>
							<div />
						</CardBody>
					</Card>
				</div>
			</div>
		</Page>
	</PageWrapper>
);


const AuthenticatedLayout: React.FC = () => {

  const { darkModeStatus, setDarkModeStatus } = useDarkMode();

  const styledBtn: IButtonProps = {
		color: darkModeStatus ? 'dark' : 'danger',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'sm',
	};

  const {authState, menu, onLogout} = useAuth();
  const { username } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const data = {redirect: location.pathname, username: username}

  
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const logout = () => {
    if (onLogout) {
      onLogout();
    }
  }

  if (!authState?.isAuthenticated) {
    return <Navigate to={`/auth/sign-in`} state={data} />
  }

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <MySidebar collapsed={collapsed} />
      
      <Layout>
        <Header style={{ padding: 0, background: '#fff' }}>
          <Row align="middle"
            style={{ border: "1px solid white",}}
          >
            <Col>
              <Button icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />} 
                className='fle-bg-coral fle-text-white mx-2' onClick={() => setCollapsed(!collapsed)}
              />
            </Col>
            <Col flex={2} className='fw-bold fle-text-teal h5'>
              My Virtual Space
            </Col>

            <Col style={{ textAlign: "right", marginLeft: 25 }}>
              <Popovers trigger='hover' desc='Dark / Light mode'>
                <BootButton
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...styledBtn}
                  onClick={() => setDarkModeStatus(!darkModeStatus)}
                  className='btn-only-icon' data-tour='dark-mode' aria-label='Toggle dark mode'>
                  <Icon icon={darkModeStatus ? 'DarkMode' : 'LightMode'} color={darkModeStatus ? 'info' : 'warning'}
                    className='btn-icon'
                  />
                </BootButton>
              </Popovers>
              
              {authState?.isAuthenticated && <Logout />}
            </Col>
          </Row>
        </Header>

        <Content
          style={{ margin: '24px 16px', padding: 24, minHeight: 280, background: colorBgContainer, borderRadius: borderRadiusLG,}}
        >
          <Suspense fallback={LOADING}>
            <Outlet /> 
          </Suspense>
        </Content>
      </Layout>
    </Layout>
  );

};

export default AuthenticatedLayout;