import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import { LogoutOutlined} from '@ant-design/icons';
import { useAuth } from "../../contexts/authContext";

export default function Logout() {

  const { authState, onLogout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = (e: any) => {
    e.preventDefault();
    if (onLogout) {
      onLogout();
    }
  };
   
  return (
    <Button className="fle-bg-coral fle-text-white fw-bold mx-2" icon={<LogoutOutlined/>}
      iconPosition={'end'} onClick={handleLogout}
    >
      Logout
    </Button>
  )
}