import React, { lazy, Suspense } from 'react';
import { Layout, theme } from 'antd';
import PageWrapper from './PageWrapper/PageWrapper';
import SubHeader from './SubHeader/SubHeader';
import Page from './Page/Page';
import Card, { CardBody } from '../components/bootstrap/Card';
import { BrowserRouter as Router, Outlet } from "react-router-dom";

const { Content } = Layout;

const LOADING = (
	<PageWrapper isProtected={false}>
		<SubHeader>
			<div />
		</SubHeader>
		<Page>
			<div className='row h-100'>
				<div className='col-lg-6'>
					<Card stretch>
						<CardBody>
							<div />
						</CardBody>
					</Card>
				</div>
				<div className='col-lg-6'>
					<Card stretch='semi'>
						<CardBody>
							<div />
						</CardBody>
					</Card>
					<Card stretch='semi'>
						<CardBody>
							<div />
						</CardBody>
					</Card>
				</div>
			</div>
		</Page>
	</PageWrapper>
);

const AUTH = {
	SIGN_IN: lazy(() => import('../pages/auth/Login')),
	PAGE_404: lazy(() => import('../pages/auth/Page404')),
};

function UnauthenticatedHeader() {
  return <p>Please login</p>
}

function UnauthenticatedContent() {
  return <p>You must login to read the message</p>
}

const UnauthenticatedLayout: React.FC = () => {

  const {token: { colorBgContainer, borderRadiusLG },} = theme.useToken();


  return (
		<Layout style={{ minHeight: "100vh" }}>
			
			<Content
				style={{ minHeight: 280, background: colorBgContainer, borderRadius: borderRadiusLG,}}
			>
				<Suspense fallback={LOADING}>
					<Outlet /> 
				</Suspense>
			</Content>
		</Layout>
  );
};

export default UnauthenticatedLayout;