import React, { lazy } from 'react';
import { RouteProps } from 'react-router-dom';
import {authMenu, rowdayPagesMenu, homePagesMenu, streamspacePagesMenu} from '../menu';

	const AUTH = {
		SIGN_IN: lazy(() => import('../pages/auth/Login')),
		PAGE_404: lazy(() => import('../pages/auth/Page404')),
	};

	const HOME = {
		DASHBOARD: lazy(() => import('../pages/home/Index')),
	};

	const ROWDAY = {
		ONEBILLIONMINUTES: lazy(() => import('../pages/conference/Index')),
	};

	const STREAMSPACE = {
		GENERALSTREAM: lazy(() => import('../pages/streamspace/Index')),
		MYSTREAM: lazy(() => import('../pages/streamspace/mystream')),
	};

	const authRoutes: RouteProps[] = [

		/**
		 * Auth Page
		 */
		{
			path: authMenu.page404.path,
			element: <AUTH.PAGE_404 />,
		},
		{
			path: authMenu.signIn.path,
			element: <AUTH.SIGN_IN />,
		},
		{
			path: authMenu.signInParam.path,
			element: <AUTH.SIGN_IN />,
		},
	];

	export const rowdayRoutes: RouteProps[] = [

		/**
		 * RowDay Menu
		 */
		{
			path: `${rowdayPagesMenu.onebillionminutes.path}`,
			element: <ROWDAY.ONEBILLIONMINUTES />,
		},
	
	];

	export const guest: RouteProps[] = [
		
		{
			path: `${streamspacePagesMenu.mystream.path}`,
			element: <STREAMSPACE.MYSTREAM />,
		},
	
	];

	const contents: RouteProps[] = [
		/**
		 * Home Menu
		 */
		{
			path: `${homePagesMenu.dashboard.path}`,
			element: <HOME.DASHBOARD />,
		},

		/**
		 * RowDay Menu
		 */
		{
			path: `${rowdayPagesMenu.onebillionminutes.path}`,
			element: <ROWDAY.ONEBILLIONMINUTES />,
		},
		
		/**
		 * Streamspace Menu
		 */
		{
			path: `${streamspacePagesMenu.generalstream.path}`,
			element: <STREAMSPACE.GENERALSTREAM />,
		},

		{
			path: `${streamspacePagesMenu.mystream.path}`,
			element: <STREAMSPACE.MYSTREAM />,
		},
	
	];

	export default contents;
