import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import contents from '../routes/contentRoutes';
import { authMenu, homePagesMenu } from '../menu';
import UnauthenticatedLayout from './UnauthenticatedLayout';
import AuthenticatedLayout from './AuthenticatedLayout';

const AUTH = {
	SIGN_IN: lazy(() => import('../pages/auth/Login')),
	PAGE_404: lazy(() => import('../pages/auth/Page404')),
};

const HOME = {
  DASHBOARD: lazy(() => import('../pages/home/Index')),
};

const MyRoutes = () => {
	return (
    <Routes>
      <Route element={<UnauthenticatedLayout />}>
        <Route path={authMenu.signIn.path} element={<AUTH.SIGN_IN />} />
        <Route path={'auth/sign-in/:username'} element={<AUTH.SIGN_IN />} />
      </Route>

      <Route element={<AuthenticatedLayout />}>
        {contents.map((page) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Route key={page.path} path={page.path} element={page.element} {...page} />
        ))}
      </Route>
        
      <Route path='*' element={<AUTH.PAGE_404 />} />
    </Routes>
	);
};

export default MyRoutes;