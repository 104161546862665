import {FC, useState } from 'react';
import { DesktopOutlined, VideoCameraOutlined, HomeOutlined, TeamOutlined} from '@ant-design/icons';
import { Layout, Menu, MenuProps, Image } from 'antd';

import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/authContext';
import Logout from '../pages/auth/Logout';

const { Sider} = Layout;

const siderStyle: React.CSSProperties = {
  scrollbarWidth: 'thin',
  scrollbarColor: 'unset',
};

interface MySidebarProps {
  collapsed: boolean
}

const MySidebar: React.FC<MySidebarProps> = ({collapsed}) => {

  const {authState, menu, username, onLogout} = useAuth();

  const menuItems: MenuProps['items'] = [
    {
      label: <Link style={{ textDecoration: 'none', fontWeight: 'bold' }} to="/"> Programs </Link>,
      icon: <HomeOutlined/>,
      key: 'home'
    }, 

    {
      label: <Link style={{ textDecoration: 'none', fontWeight: 'bold' }} to={`/generalstream`}> General Livestream </Link>,
      icon: <DesktopOutlined />,
      key: 'generalstream'
    },

    {
      label: <Link style={{ textDecoration: 'none', fontWeight: 'bold' }} to={`/${username}/mystream`}> My Stream Space </Link>,
      icon: <VideoCameraOutlined/>,
      key: 'mystream'
    },

    {
      label: <Link style={{ textDecoration: 'none', fontWeight: 'bold', marginBottom: 5 }} to={`/${username}/1billionminutes`}> 1 Billion minutes prayer </Link>,
      icon: <TeamOutlined />,
      key: '1billionminutes'
    }, 

  ]

  const guestMenu: MenuProps['items'] = [
    {
      label: <Link style={{ textDecoration: 'none', fontWeight: 'bold' }} to={`/${username}/mystream`}> My Stream Space </Link>,
      icon: <VideoCameraOutlined/>,
      key: 'mystream'
    },

  ]

  return (
    <Sider trigger={null} collapsible collapsed={collapsed} width={220} collapsedWidth={0} theme='light' style={siderStyle}>

      <div className="mb-4 fle-bg-teal py-2">
        <div className="profile">
          <div className="profile-pic"></div>
        </div>
        <div className="text-center h6 fle-text-white fw-bold">
          Translators Network International
        </div>
      </div>

      <Menu theme="light" mode="inline" defaultSelectedKeys={['home']}
        style={{  maxHeight: "calc(100vh - 150px)", overflowX: "hidden", overflowY: "auto"}}
        items={menu === 'account' ? menuItems : guestMenu}
      />

    </Sider>
  );
};

export default MySidebar;